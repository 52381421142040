<template>
  <div class="page page__articles-item">
    <esmp-loader v-show="loading" fix />

    <article-item
      v-if="articlesItem"
      :title="articlesItem.title"
      :content="articlesItem.content"
      :main-image-url="articlesItem.mainImageUrl"
      :create-date="articlesItem.startDatetime"
      :attachments="articlesItem.attachments"
    />
  </div>
</template>

<script>
import ArticleItem from '@/components/article-item';
import getFileExtension from '@/helpers/getFileExtension';
import getFileFormat from '@/helpers/getFileFormat';

export default {
  name: 'ArticlesItem',

  components: { ArticleItem },

  data() {
    return {
      // TODO: Статичные данные, заменить на данные с сервера
      articlesItem: {
        content: '<p>«Ростелеком» выступил партнером Дня Военно-Морского Флота в Санкт-Петербурге !!!!!</p>',
        id: 23,
        mainImageUrl: 'https://dummyimage.com/335/767/000.png',
        startDatetime: 1636757545000,
        title: '«Ростелеком» выступил партнером Дня Военно-Морского Флота в Санкт-Петербурге',
      },
      loading: false,
    };
  },

  computed: {
    currentArticlesItemId() {
      return this.$route.params.id;
    },
  },

  created() {
    // TODO: Endpoint еще не реализован
    // this.getArticlesItem();
  },

  methods: {
    async getArticlesItem() {
      this.loading = true;

      const { data: articlesItem } = await this.$API.articles.getArticlesItem(this.currentArticlesItemId);

      articlesItem.attachments = articlesItem.files.map((file) => ({
        ...file,
        name: file.originalFilename,
        format: getFileFormat(file.originalFilename),
        extension: getFileExtension(file.originalFilename),
      }));

      this.articlesItem = articlesItem;

      this.loading = false;

      this.$router.setMetaOption(this.$route, 'title', articlesItem.title);
    },
  },
};
</script>
